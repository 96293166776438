import iconOne from '../../assets/service/1.png';
// import iconTwo from '../../assets/service/2.png';
import iconTwo from '../../assets/service/medicine.png';
import iconThree from '../../assets/service/3.png';
// import iconFour from '../../assets/service/4.png';
import iconFour from '../../assets/service/tooth.png';
import iconFive from '../../assets/service/rootcanaltreatment.png'

const ServicesData = [
    {
        'icon': iconOne,
        'title': 'Orthodontic Treatment',
        'description': 'Smile confidently. Orthodontics can straighten your teeth and improve your bite.',
    },
    {
        'icon': iconTwo,
        'title': 'Implants',
        'description': 'Dental implants with Swiss Straumann implants - Internet crowns (all types of crowns) and dental fillings',
    },
    {
        'icon': iconThree,
        'title': 'Whitening',
        'description': 'Teeth whitening of both types (home / laser whitening) and the latest devices and the best international whitening materials',
    },
    {
        'icon': iconFour,
        'title': 'Gum cleaning',
        'description': 'Gum cleaning (one channel or two channels or three channels) regular or through the rotary device',
    },
    {
        'icon': iconFive,
        'title': 'Root canal treatment',
        'description': 'Root canal treatment for all teeth using Rotary devices and the latest technology for root canal disinfection and filling.',
    }
    // Root canal treatment for all teeth using Rotary devices and the latest technology for root canal disinfection and filling.
]

export default ServicesData;