import React from 'react';
import './Banner.scss';
import {Link} from 'react-router-dom';
import icon from '../../assets/banner/icons/Calling.png';
import bannerImg from '../../assets/banner/1.png';
import doctorImg from '../../assets/dr_yousef_50.jpg';
import bannerPattern from '../../assets/banner/pattern_02.png';
import shapeOne from '../../assets/banner/vector_01.png';
import shapeTwo from '../../assets/banner/vector_02.png';
import shapeThree from '../../assets/banner/vector_03.png';
import shapeFour from '../../assets/banner/pattern.png';

  const PhoneCallButton = () => {
    const phoneNumber = "+962792602666"; // Ensure it's in proper format
  
    return (
      <div>
        <p>Call us:</p>
        <a href={`tel:${phoneNumber}`}>
          <button className="buttonStyle">Call Now</button>
        </a>
      </div>
    );
  };
const   Banner = () => {

    return (
        <section className='section-bg section-common banner-section'>
            <div className="d-table">
                <div className="d-table-cell">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="banner-text" data-aos="fade-up" data-aos-duration="2000">
                                    <h1>Healthy teeth, happy life</h1>
                                    <p>Dr. Yousof AlSaket is a skilled dentist with a strong foundation in oral and dental medicine and surgery. He earned his Doctor of Dental Surgery (DDS) degree from the University of Jordan. He then got his Membership of the Faculty of Dentistry at the Royal College of Surgeons in Ireland (MFD RCSI). 
To further enhance his expertise, Dr. Yousof Alsaket completed a fellowship in Aesthetic and Restorative Dentistry at the University of Genoa, Italy. This comprehensive background equips him to provide a wide range of dental services, including general dentistry, oral surgery, and cosmetic procedures.</p>

                                    <div className="banner-bottom">
                                        <div className="theme-btn">
                                            <Link to="/contact">Book an appointment</Link>
                                        </div>

                                        <div className="banner-call" onClick={PhoneCallButton} >
                                            <div className='icon'>
                                                <img src={icon} alt="icon" />
                                            </div>
                                            <div className='call-text'>
                                                <p>Take Care Of Your Smile</p>
                                                <h6>079 260 2666</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6">
                                <div className="banner-img-area" data-aos="fade-up" data-aos-duration="2000" data-aos-delay="500">
                                    <div className="banner-img" >
                                        <img src={bannerImg} alt="banner model" />
                                    </div>
                                    <div className='info-box'>
                                        <div className="info-img">
                                            <img src={doctorImg} alt="doctor" />
                                        </div>
                                        <div className='info-text'>
                                            <p>Dr. Yousof Al-Saket</p>
                                            <p><small>Consultant</small></p>
                                        </div>
                                    </div>  

                                    {/* Vector Shapes */}
                                    <div className="shapes">
                                        <img src={shapeOne} alt="shape" />
                                        <img src={shapeTwo} alt="shape" />
                                        <img src={shapeThree} alt="shape" />
                                        <img src={shapeFour} alt="shape" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Banner Pattern Vector*/}
            <img className='banner-pattern' src={bannerPattern} alt="banner pattern" />
        </section>
    );
};

export default Banner;