import React from 'react';
import './ContactForm.scss';
import icon from '../../assets/banner/icons/Calling.png';



const ContactForm = () => {
    const handleClick = () => {
        const whatsappLink = 'https://api.whatsapp.com/send?phone=+962792602666&text=Hello%20doctor%20can%20I%20book%20an%20appointment?';
        window.open(whatsappLink, '_blank');
    };
    return (
        <div>
            <div className="row">
                {/* <div className="col-lg-6">
                    <div class="form-group">
                        <label>Name</label>
                        <input type="email" class="form-control" placeholder="Enter your name..." />
                    </div>
                </div>
                <div className="col-lg-6">
                    <div class="form-group">
                        <label>E-mail Address</label>
                        <input type="email" class="form-control" placeholder="Enter email address..." />
                    </div>
                </div>
                <div className="col-lg-6">
                    <div class="form-group">
                        <label>Service</label>
                        <select class="form-control">
                            <option>Teeth Whitening</option>
                            <option>Teeth Whitening</option>
                            <option>Teeth Whitening</option>
                            <option>Teeth Whitening</option>
                        </select>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div class="form-group">
                        <label>Department</label>
                        <select class="form-control">
                            <option>Select Department</option>
                            <option>Select Department</option>
                            <option>Select Department</option>
                            <option>Select Department</option>
                        </select>
                    </div>
                </div>
                <div className="col-lg-12">
                    <div class="form-group">
                        <label for="exampleFormControlTextarea1">Messages</label>
                        <textarea class="form-control" placeholder='Enter your messages...' rows="3"></textarea>
                    </div>
                </div> */}

                <div className="col-lg-6">
                    <button onClick={handleClick} class="btn appointment-btn">Book an appointment</button>

                </div>
                <div className="col-lg-6">
                    <div className="appointment-call">
                        <div className='icon'>
                            <img src={icon} alt="icon" />
                        </div>
                        <div className='call-text'>
                            <p>TAKE CARE OF YOUR SMILE HERE</p>
                            <h6>079 260 2666</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactForm;