import React from 'react';
import SectionTitle from '../../components/SectionTitle/SectionTitle';
import './Accredited.scss';
import AccreditedData from './AccreditedData';
import Service from '../../components/Service/Service';


const Accredited = () => {
    return (
        <section className='service-section pt-100 pb-70' data-aos="fade-up" data-aos-duration="2000">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-sm-6">
                        <SectionTitle title="We are approved by many insurance companies." subTitle="Insurance"/>
                    </div>
                    {/* <div className="col-lg-6 col-sm-6">
                        <p className='service-title-text'>Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.</p>
                    </div> */}
                </div>

                <div className="row">
                    {
                        AccreditedData.map(singleService => <Service serviceList={singleService} accredited = {true}/>)
                    }
                </div>
            </div>

            {/* <div className="services-link text-center">
                <Link to='/'>
                    View all service list
                    <BsFillArrowRightCircleFill/>
                </Link>
            </div> */}
        </section>
    );
};

export default Accredited;