import React from 'react';
import Navbar from '../components/Navbar/Navbar';
import Appointment from '../sections/Appointment/Appointment';
import Banner from '../sections/Banner/Banner';
import Features from '../sections/Features/Features';
import Footer from '../sections/Footer/Footer';
import Services from '../sections/Services/Services';
import Accredited from '../sections/Accredited/Accredited'
const Home = () => {

    return (
        <>
            <Navbar/>
            <Banner/>
            <Services/>
            {/* <Emergency/> */}
            <Features />
            {/* <Expert/> */}
            {/* <Testimonial/> */}
            {/* <Blogs/> */}
            <Accredited/>
            <Appointment/>
            <Footer/>
        </>
    );
};

export default Home;