import euroarab from '../../assets/Accredited/euroarab.jpeg';
import gig from '../../assets/Accredited/gig.jpeg';
import globemed from '../../assets/Accredited/globemed.jpeg';
import iiab from '../../assets/Accredited/iiab.jpeg';
import mednet from '../../assets/Accredited/mednet.jpeg';
import medconnect from '../../assets/Accredited/medconnect.jpeg';
import medservice from '../../assets/Accredited/medservice.jpeg';
import nethealth from '../../assets/Accredited/nethealth.jpeg';
import solidarity from '../../assets/Accredited/solidarity.jpeg';

const ServicesData = [
    {
        'icon': medconnect,
        'title': 'MEDCONNECT',
        // 'description': 'Smile confidently. Orthodontics can straighten your teeth and improve your bite.',
    },
    {
        'icon': nethealth,
        'title': 'NatHealth',
        // 'description': 'Dental implants with Swiss Straumann implants - Internet crowns (all types of crowns) and dental fillings',
    },
    {
        'icon': medservice,
        'title': 'Med. Service',
        // 'description': 'Teeth whitening of both types (home / laser whitening) and the latest devices and the best international whitening materials',
    },
    {
        'icon': mednet,
        'title': 'Med Net',
        // 'description': 'Gum cleaning (one channel or two channels or three channels) regular or through the rotary device',
    },
    {
        'icon': gig,
        'title': 'GIG Jordan',
        // 'description': 'Gum cleaning (one channel or two channels or three channels) regular or through the rotary device',
    },
    {
        'icon': solidarity,
        'title': 'SOLIDARITY',
        // 'description': 'Gum cleaning (one channel or two channels or three channels) regular or through the rotary device',
    },
    {
        'icon': euroarab,
        'title': 'EURO ARAB',
        // 'description': 'Gum cleaning (one channel or two channels or three channels) regular or through the rotary device',
    },
    {
        'icon': iiab,
        'title': 'ISLAMIC INTERNATIONAL ARAB BANK',
        // 'description': 'Gum cleaning (one channel or two channels or three channels) regular or through the rotary device',
    },
    {
        'icon': globemed,
        'title': 'GlobeMed',
        // 'description': 'Gum cleaning (one channel or two channels or three channels) regular or through the rotary device',
    },   

]

export default ServicesData;